<template>
    <div class="panel-body">
        <h3>Shipping Manifest Emails</h3>
        <div v-show="showInput" class="row">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Email</label>
                <input type="text" class="form-control" v-model="emailAddress" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Location</label><br />
                <select v-model="emailLocation" class="form-control">
                    <option value="0">Location</option>
                    <option v-for="[index, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="index" :value="index">{{ location }}</option>
                </select>
            </div>
            <div class="col-xs-12">
                <button @click="addEmail" class="btn btn-default">Submit</button>
                <button @click="toggleAddresses" class="btn btn-default">Cancel</button>
            </div>

        </div>

        <button v-show="!showInput" @click="toggleAddresses" class="btn btn-default">Add Email</button>

        <div class="form-inline spacing_top">
            <span v-for="(mail, index) in email" v-bind:key="mail.shipping_mainfest_email_id">
                <span @click="deleteEmail(mail.shipping_manifest_email_id)" class="glyphicon glyphicon-trash cdi_link"></span>
                    {{ mail.email }} - {{ mail.cdi_location }}
                <span v-if="index != email.length - 1">, </span>
            </span>
        </div>

        <ConfirmBox
            v-if="showConfirm"
            :heading="'Remove Email?'"
            :body="'This person will no longer receive Shipping Manifest emails.'"
            @close="confirmDelete"
        />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store';
import ConfirmBox from '@/components/utils/ConfirmBox';
import cdiLocationCache from '@/cache/cdiLocation.cache';

export default {
    name: 'ManifestEmails',
    components: {ConfirmBox},
    props: ['email'],
    data() {
        return {
            showInput: false,
            showConfirm: false,
            emailAddress: '',
            emailLocation: '0',
            deleteId: 0,
            cache: {
                cdiLocationCache
            }
        }
    },
    methods: {
        toggleAddresses: function() {
            this.showInput = !this.showInput;
            if (!this.showInput) {
                this.emailAddress = '';
                this.emailLocation = 0;
            }
        },
        deleteEmail: function(id) {
            this.deleteId = id;
            this.showConfirm = true;
        },
        confirmDelete: function(confirmed) {
            if (confirmed && this.deleteId != 0) {
                store.api('remove_shipping_manifest_email', {'params[shipping_manifest_email_id]' : this.deleteId}, 'object.data');
            }
            this.showConfirm = false;
            this.deleteId = 0;
        },
        addEmail: function() {
            if (this.emailLocation > 0 && this.$appStore.validateEmail(this.emailAddress)) {
                store.api('add_shipping_manifest_email', {'params[email]': this.emailAddress, 'params[cdi_location_id]': this.emailLocation}, 'object.data');
                this.showInput = false;
            } else {
                this.$appStore.errorBox('You must enter a valid email address and select a location.');
            }

        }
    }
}

</script>

<style scoped>

</style>