<template>
    <div>
        <h3>Automatic Routing</h3>
        <div class="row">
            <div class="col-sm-6 col-xs-12 form-group"><label>Users</label><Typeahead cdi_type='user'  clear-on-select="true" v-bind:ID.sync='cdi_id' @objectSelected="addRouter('user_website')" /></div>
        </div>
        <ul class="box_list">
            <li class="cdi_button btn box_list li" v-for="(user) in routing.users" v-bind:key="user.cdi_id">
                {{ user.cdi_value }}
                <span @click="deleteConfirmation('deleteRouter','user_website', user.cdi_id)" class="glyphicon glyphicon-trash cdi_link"></span>
            </li>
        </ul>
        <div class="row spacing_top">
            <div class="col-sm-6 col-xs-12 form-group"><label>Companies</label><Typeahead  clear-on-select="true" cdi_type='company'  v-bind:ID.sync='cdi_id' @objectSelected="addRouter('company')"/></div>
        </div>
        <ul class="box_list">
            <li class="cdi_button btn box_list li" v-for="(company) in routing.companies" v-bind:key="company.cdi_id">
                {{ company.cdi_value }}
                <span @click="deleteConfirmation('deleteRouter', company.cdi_type, company.cdi_id)" class="glyphicon glyphicon-trash cdi_link"></span>
            </li>
        </ul>
        <div class="row spacing_top">
            <div class="col-sm-6 col-xs-12 form-group"><label>Zipcodes</label><input type="text" v-model='cdi_id' @keypress.enter="addZip()" class="form-control"></div>
        </div>
        <ul class="box_list">
            <li class="cdi_button btn box_list li" v-for="(zip) in routing.zips" v-bind:key="zip.zip">
                {{ zip.zip }}
                <span @click="deleteConfirmation('deleteZip','', zip.zip)" class="glyphicon glyphicon-trash cdi_link"></span>
            </li>
        </ul>
        <div class="row spacing_top">
            <div class="col-sm-6 col-xs-12 form-group"><label>States</label><typeahead cdi_type='state'  clear-on-select="true" v-bind:ID.sync='cdi_id' @objectSelected="addRouter('state')"/></div>
        </div>
        <ul class="box_list">
            <li class="cdi_button btn box_list li" v-for="(state) in routing.states" v-bind:key="state.cdi_id">
                {{ state.cdi_value }}
                <span @click="deleteConfirmation('deleteRouter', state.cdi_type, state.cdi_id)" class="glyphicon glyphicon-trash cdi_link"></span>
            </li>
        </ul>
        <div class="row spacing_top">
            <div class="col-sm-6 col-xs-12 form-group"><label>Blacklist Companies</label><typeahead cdi_type='company'  clear-on-select="true" v-bind:ID.sync='cdi_id' @objectSelected="addBlacklist()"/></div>
        </div>
        <ul class="box_list">
            <li class="cdi_button btn box_list li" v-for="(company) in routing.blacklists" v-bind:key="company.company_id">
                {{ company.company_name }}
                <span @click="deleteConfirmation('deleteBlacklist', '', company.company_id)" class="glyphicon glyphicon-trash cdi_link"></span>
            </li>
        </ul>
        <ConfirmBox
            v-if="confirmBox.show"
            :heading="'Remove Routing'"
            :body="'Remove Routing?'"
            @close="confirmedDelete"
        />
    </div>
</template>
<script>
import Typeahead from "@/components/utils/typeaheads/Typeahead";
 import { store } from '@/store/BusinessObject.store';
import ConfirmBox from "@/components/utils/ConfirmBox";



export default {
    name: 'AutoRouting',
    components: {Typeahead, ConfirmBox},
    props: ['routing'],
    data() {
        return {
            cdi_id: '',
            endpoint: '',
            params: '',
            confirmBox: {
                show: false,
                function: '',
                target: 0,
                table: ''
            }
        }
    },
    methods: {
        addRouter: function(table) {
            store.apiWithObjectLoad('add_routing', {
                'params[cdi_table]': table,
                'params[cdi_id]': this.cdi_id,
            });
            this.cdi_id = '';
        },
        addZip: function() {
            store.apiWithObjectLoad('add_routing_zip', {
                'params': this.cdi_id,
            });
            this.cdi_id = '';
        },
        addBlacklist: function() {
            store.apiWithObjectLoad('add_blacklist', {
                'params': this.cdi_id,
            });
            this.cdi_id = '';
        },
        deleteRouter: function () {

            store.apiWithObjectLoad('delete_routing', {
                'params[cdi_table]': this.confirmBox.table,
                'params[cdi_id]': this.confirmBox.target,
            });
        },
        deleteZip: function () {
            store.apiWithObjectLoad('delete_routing_zip', {
                'params': this.confirmBox.target,
            });
        },
        deleteBlacklist: function() {
            store.apiWithObjectLoad('delete_blacklist', {
                'params': this.confirmBox.target,
            });
        },
        confirmedDelete: function(confirmed) {
            if (confirmed) {
                if (this.confirmBox.function === 'deleteRouter') {
                    this.deleteRouter();
                } else if (this.confirmBox.function === 'deleteZip') {
                    this.deleteZip();
                } else if (this.confirmBox.function === 'deleteBlacklist') {
                    this.deleteBlacklist();
                }
            }
            this.resetConfirmBox();
        },
        resetConfirmBox: function() {
            this.confirmBox.function = '';
            this.confirmBox.show = false;
            this.confirmBox.target = 0;
        },
        deleteConfirmation: function(fn, table, id) {
            this.confirmBox.function = fn;
            this.confirmBox.table = table;
            this.confirmBox.target = id;
            this.confirmBox.show = true;
        },
    },
}
</script>
<style scoped>

</style>