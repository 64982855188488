<template>
    <div>
        <h3>Add ePRO Numbers</h3>
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-xs-12 form-group">
                        <label>Starting PRO</label>
                        <input v-model="eproStart" type="text" class="form-control">
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12 form-group">
                        <label>Ending PRO</label>
                        <input v-model="eproEnd" type="text" class="form-control">
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12 form-group">
                        <label>Increment</label>
                        <input v-model="increment" type="text" class="form-control">
                    </div>
                    <div class="col-lg-4 col-sm-6 col-xs-12 form-group">
                        <label>Location</label>
                        <select v-model="location" class="form-control">
                            <option value="0">Any</option>
                            <option v-for="[index, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="index" :value="index">{{ location }}</option>
                        </select>
                    </div>
                    <div class="col-xs-12 form-group">
                        <button type="button" class="btn btn-default cdi_button" @click="addEpro">Add ePROs</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import cdiLocationCache from '@/cache/cdiLocation.cache';
import { store } from '@/store/BusinessObject.store';

export default {
    name: 'Epro',
    data() {
        return {
            eproStart: '',
            eproEnd: '',
            increment: 1,
            location: 0,
            cache: {
                cdiLocationCache
            }
        }
    },
    methods: {
        addEpro: function() {
            store.apiWithObjectLoad('insert_epros', {
                'params[starting_epro]': this.eproStart,
                'params[ending_epro]': this.eproEnd,
                'params[increment]': this.increment,
                'params[cdi_location_id]': this.location
            });
            this.eproStart = '';
            this.eproEnd = '';
            this.increment = 1;
            this.location = 0
        }
    }
}

</script>