<template>
    <div>
        <div v-if="id">
            <h1>{{ state.object.data.shipping_co }}</h1>
            <Details  :data="state.object.data" />
            <ManifestEmails :email="state.object.data.shipping_manifest_emails" />
            <Epro v-show="state.object.data.epro" />
            <AutoRouting :routing="state.object.data.routing" />
            <History :history="state.object.data.history" />
        </div>

        <New v-else-if="isCurrentView('new')" />
        
        <Search v-else :hideFields="true" />
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import { shipperStore } from './Shipper.store';
import History from '@/components/businessObjects/utils/History';
import Search from "@/components/businessObjects/utils/Search";
import New from './New';
import Details from '@/components/businessObjects/utils/Details'
import Epro from '@/components/businessObjects/shipper/Epro';
import ManifestEmails from '@/components/businessObjects/shipper/ManifestEmails';
import AutoRouting from './AutoRouting';


export default {
    name: 'Container',
    components: {
        ManifestEmails,
        Epro,
        Search,
        History,
        New,
        Details,
        AutoRouting
    },
    data() {
        return {
            state: store.state,
            shipperState: shipperStore.state
        }
    },
    props: ['id'],
    computed: {
        currentView: function() {
            return this.state.currentView;
        },
    },
    methods: {
        load: function() {
            if (this.id){
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([this.state.object.data.shipping_co, this.state.titlePlural]));
            }
        },
        isCurrentView: function(view) {
            return view === this.state.currentView;
        }
    },
    created() {
        store.initialize(this.shipperState);
        this.load();
    },
    watch: {
        id: function() {
            this.load();
        },
        currentView: function() {
            if(this.currentView == 'search'){
                window.location.reload()
            }
        }
    }
}

</script>