<template>
    <div>
        <h1>New Shipper</h1>
        <form @submit.prevent="newShipper">
            <div class="row">
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Shipper Name</label>
                    <input type="text" v-model="shipping_co" class="form-control">
                </div>
            </div>
            <input type="submit" class="btn btn-default" value="Save">
        </form>
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store.js';
export default {
    name: "New",
    data() {
        return {
            state: store.state,
            shipping_co: '',
        }
    },
    created() {
        store.clearObjectData();
    },
    methods: {
        newShipper: function() {
            store.api('new_shipper',{'params[shipping_co]': this.shipping_co})
                .then(result => {
                        this.$router.push({path: `/${this.state.nav.base}/${result.my_id}`});
                    }
                );
        }
    },

}
</script>

<style scoped>

</style>