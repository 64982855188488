import shippingCache from '@/cache/shipping.cache'

export const shipperStore = {
	state: {
		name: 'shipper',
		title: 'Shipper',
		titlePlural: 'Shippers',
		nav: {
			base: 'shipper',
			sideNav: true,
			newView: true,
			hideUpload: true,
			views: [
				{
					view: 'details',
					label: 'Details',
				}
			]
		},
		search: {
			endPoint: 'shippers_rpt',
			formFields: {
				searchIndex: {
					val: 0,
					qsField: '[search_index]'
				}
			},
			resultFields: {
				shipping_co_id: {header: 'ID', link: 'shipper'},
				shipping_co: {header: 'Shipper', link: ''},
				phone: {header: 'Phone', link: ''},
				url: {header: 'link', displayField: 'short_url', link: 'external'}
			}
		},
		object: {
			fields: [
				{
					name: 'shipping_co_id',
					label: 'Shipper ID',
					component: 'DetailsStatic'
				},
				{
					name: 'shipping_co',
					label: 'Name',
					component: 'DetailsStatic'
				},
				{
					name: 'phone',
					label: 'Phone',
					component: 'DetailsText'
				},
				{
					name: 'trucking_options_id',
					label: 'Trucking',
					component: 'DetailsSelect',
					options: shippingCache.SHIPPING_OPTIONS
				},
				{
					name: 'ship_account_num',
					label: 'Ship Acc',
					component: 'DetailsSelect',
					options: [
						[4035, 4035],
						[4040, 4040],
						[4045, 4045],
						[4050, 4050],
						[4055, 4055]
					]
				},
				{
					name: 'code',
					label: 'Code',
					component: 'DetailsStatic'
				},
				{
					name: 'disable',
					label: 'Disabled',
					component: 'DetailsCheckbox'
				},
				{
					name: 'epro',
					label: 'E-PRO Numbers',
					displayField: 'epro_label',
					component: 'DetailsCheckbox'
				},
				{
					name: 'epro_alert',
					label: 'E-PRO Alert',
					component: 'DetailsText'
				},
				{
					name: 'shipping_co_user_id',
					link: 'user',
					label: 'CDI User',
					component: 'DetailsTypeahead',
					cdiType: 'search_my_users',
					displayField: 'shipping_co_user'
				},
				{
					name: 'shipping_co_username',
					label: 'Username',
					component: 'DetailsText'
				},
				{
					name: 'shipping_co_passwd',
					label: 'Password',
					component: 'DetailsText'
				},
				{
					name: 'shipping_co_notes',
					label: 'Notes',
					component: 'DetailsTextarea'
				},
				{
					name: 'url',
					label: 'URL',
					component: 'DetailsText'
				},
				{
					name: 'csv_shipping_id',
					label: 'Shipping CSV',
					component: 'DetailsSelect',
					options: shippingCache.SHIPPING_CSV_OPTIONS
				},
				{
					name: 'carrier',
					label: 'Carrier',
					component: 'DetailsText'
				},
				{
					name: 'port_key',
					label: 'Port Carrier Key',
					component: 'DetailsText'
				}
			]
		}
	}
}